/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it

exports.onRouteUpdate = () => {
    if (typeof window !== `undefined`) { window.scrollTo(0, 0) }
}

exports.shouldUpdateScroll = args => {
    return false;
};
